import React, { Fragment } from "react"
import styled from "styled-components"
import PagesContext from "../contexes/PagesContext"
import { nthIndex } from "../util/strings"
import { Link } from "gatsby"
import { withTranslation } from "react-i18next"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Icon from "../assets/right-arrow.svg"
import withSimpleErrorBoundary from "../util/withSimpleErrorBoundary"

const StyledLink = styled(Link)`
  font-family: "Josefin Sans", sans-serif;
  font-size: 1.6rem;
  font-weight: 600;
  color: rgba(51, 51, 51, 1) !important;
  text-decoration: none;
  border-radius: 0.25rem;
  /*   margin: 1rem 0; */
  display: inline-block;
  width: 100%;
  line-height: 1.3;
  border-radius: 10px;
  transition: background-color 0.2s;

  &:hover {
    text-decoration: none;
    color: rgba(51, 51, 51, 0.8) !important;
  }
`
const StyledDiv = styled.div`
  font-family: "Josefin Sans", sans-serif;
  font-size: 1.6rem;
  font-weight: 600;
  color: rgba(51, 51, 51, 1) !important;
  text-decoration: none;
  border-radius: 0.25rem;
  /*   margin: 1rem 0; */
  display: inline-block;
  width: 100%;
  line-height: 1.3;
  border-radius: 10px;
  transition: background-color 0.2s;
`

const Wrapper = styled.div`
  margin-top: 1rem;
  background: #f1f1f1;
  padding: 1rem 1.5rem;

  span {
    color: #333;
    opacity: 0.6;
    font-family: "Josefin Sans", sans-serif;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  color: #333;
`

class EndOfSubSection extends React.Component {
  render() {
    return (
      <PagesContext.Consumer>
        {(value) => {
          const currentPath = value.current.frontmatter.path
          let sectionPath = currentPath
          const sectionSeparator = nthIndex(currentPath, "/", 2)
          if (sectionSeparator !== -1) {
            sectionPath = currentPath.substr(0, sectionSeparator)
          }
          const sectionPages = value.all
            .filter((o) => o.path.startsWith(`${sectionPath}/`))
            .sort((a, b) => {
              a = a.path.toLowerCase()
              b = b.path.toLowerCase()

              return a > b ? 1 : b > a ? -1 : 0
            })

          let currentPageIndex = null

          sectionPages.forEach((page, i) => {
            if (page.path !== currentPath) {
              return
            }
            currentPageIndex = i
          })

          let nextPart = null

          if (
            currentPageIndex !== null &&
            currentPageIndex !== sectionPages.length - 1
          ) {
            nextPart = sectionPages[currentPageIndex + 1]
          }

          const currentChapter = parseInt(sectionPath.split("-")[1])

          const regex = new RegExp("/part-[1-9]$", "gm")
          const chapters = value.all
            .filter((o) => o.path.match(regex))
            .sort((a, b) => {
              return a.path > b.path ? 1 : -1
            })

          const nextSectionPath = `/part-${currentChapter + 1}`
          const nextSectionPages = value.all
            .filter((o) => o.path.startsWith(`${nextSectionPath}/`))
            .sort((a, b) => {
              a = a.path.toLowerCase()
              b = b.path.toLowerCase()

              return a > b ? 1 : b > a ? -1 : 0
            })

          return (
            <Wrapper>
              {this.props.t("endReached")}{" "}
              {nextPart && (
                <Fragment>
                  <span>{this.props.t("continueToNext")}</span>
                  <ButtonWrapper>
                    <StyledLink to={nextPart.path} role="link">
                      {nextPart.title}
                    </StyledLink>
                    <Link to={nextPart.path} aria-hidden="true">
                      <Icon width="30px" />
                    </Link>
                  </ButtonWrapper>
                </Fragment>
              )}
              {nextPart === null && currentChapter + 1 <= chapters.length && (
                <Fragment>
                  <span>Seuraava luku</span>
                  <ButtonWrapper>
                    <StyledLink to={nextSectionPath} role="link">
                      {nextSectionPages[0].title}
                    </StyledLink>
                    <Link to={nextSectionPath} aria-hidden="true">
                      <Icon width="30px" />
                    </Link>
                  </ButtonWrapper>
                </Fragment>
              )}
              {nextPart === null && currentChapter + 1 > chapters.length && (
                <Fragment>
                  <span>Hyvää työtä!</span>
                  <ButtonWrapper>
                    <StyledDiv>Olet päässyt kurssin loppuun</StyledDiv>
                  </ButtonWrapper>
                </Fragment>
              )}
              <p>{this.props.t("rememberToCheckPoints")}</p>
            </Wrapper>
          )
        }}
      </PagesContext.Consumer>
    )
  }
}

export default withTranslation("common")(
  withSimpleErrorBoundary(EndOfSubSection),
)
