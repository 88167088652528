import { graphql, navigate } from "gatsby"
import React, { Fragment } from "react"
import { Helmet } from "react-helmet"
import rehypeReact from "rehype-react"
import styled from "styled-components"
import Thumb06 from "../assets/business_cycle.svg"
import Thumb05 from "../assets/economic_growth.svg"
import Thumb03 from "../assets/externalities_public_finance.svg"
import Thumb08 from "../assets/GDP.svg"
import Thumb07 from "../assets/global_challenges.svg"
import Thumb02 from "../assets/markets_and_price_discovery.svg"
import Thumb04 from "../assets/savings.svg"
import Thumb01 from "../assets/understanding_the_economy_v04.svg"
import Container from "../components/Container"
import EndOfSubSection from "../components/EndOfSubSection"
import Navbar from "../components/Navbar/Navbar"
import ScrollIndicator from "../components/ScrollIndicator"
import LoginStateContext, {
  LoginStateContextProvider,
} from "../contexes/LoginStateContext"
import PagesContext from "../contexes/PagesContext"
import getNamedPartials from "../partials"
import Header from "../partials/Contentpage/Header"
import H3 from "../partials/Headers/H3"
import { getCachedUserDetails, loggedIn } from "../services/moocfi"
import { tryToScrollToSelector } from "../util/dom"
import { nthIndex } from "../util/strings"
import Layout from "./Layout"
import Completed from "../partials/CourseProgress/Completed"
import "./remark.css"

const BoldHeading = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
  margin: 1.4rem 0 3rem 0;

  H3 {
    font-family: "Josefin Sans", sans-serif;
    font-weight: 600;
    font-size: clamp(28px, 3vw, 4rem);
    color: #333;
    text-align: center;
  }

  h5 {
    font-family: "Josefin Sans", sans-serif;
    font-size: clamp(20px, 2vw, 20px);
    color: #333;
    text-transform: uppercase;
    font-weight: 500;
    text-align: center;
  }
`

const ContentWrapper = styled.article``

const arr = {
  "part-1": ["#34BF9F", Thumb01, "Talouden maailma", "#333"],
  "part-2": [
    "rgb(255, 209, 90)",
    Thumb02,
    "Markkinat ja hinnanmuodostus",
    "#333",
  ],
  "part-3": ["#2D4CAD", Thumb03, "Ulkoisvaikutukset ja julkistalous", "white"],
  "part-4": ["#7C73D9", Thumb04, "Rahoitusmarkkinat ja investoiminen", "white"],
  "part-5": ["#4E84B2", Thumb05, "Talouskasvu", "white"],
  "part-6": ["#50E3C2", Thumb06, "Suhdannevaihtelut", "#333"],
  "part-7": ["#4C76C9", Thumb07, "Globaalit haasteet", "white"],
  "bkt-liite": ["#34BF9F", Thumb08, "Mikä on bruttokansantuote?"],
  "Missing topic": ["#4C76C9", Thumb07, "Missing topic"],
  "": ["#4C76C9", Thumb07, ""],
  undefined: ["#4C76C9", Thumb07, "undefined"],
}

export default class CourseContentTemplate extends React.Component {
  static contextType = LoginStateContext

  async componentDidMount() {
    if (typeof window !== "undefined" && window.location.hash) {
      const selector = window.location.hash
      setTimeout(() => {
        tryToScrollToSelector(selector)
      }, 100)
      setTimeout(() => {
        tryToScrollToSelector(selector)
      }, 500)
      setTimeout(() => {
        tryToScrollToSelector(selector)
      }, 1000)
      setTimeout(() => {
        tryToScrollToSelector(selector)
      }, 2000)
    }

    if (!loggedIn()) {
      return
    }

    let userInfo = await getCachedUserDetails()
    const submitted = userInfo?.extra_fields?.submitted
    if (submitted === undefined) {
      navigate("/missing-info")
    }
  }

  render() {
    const { data } = this.props
    const { frontmatter, htmlAst } = data.page
    const allPages = data.allPages.edges.map((o) => o.node?.frontmatter)
    const partials = getNamedPartials()
    const renderAst = new rehypeReact({
      createElement: React.createElement,
      components: partials,
    }).Compiler

    const filePath = data.page.fileAbsolutePath.substring(
      data.page.fileAbsolutePath.lastIndexOf("/data/"),
      data.page.fileAbsolutePath.length,
    )

    const currentPath = frontmatter.path
    const sectionSeparator = nthIndex(currentPath, "/", 2)
    var topic = ""
    var partNumber = ""
    var subSectionNumber = ""
    var partName = ""
    var dot = ""
    if (currentPath.startsWith("/part") === true) {
      partNumber = currentPath.substr(6, 1)
      subSectionNumber = currentPath.substr(8, 1)

      var partPath = currentPath
      dot = "."
      partName = partPath.substr(1, partPath.length - 1)
      if (sectionSeparator !== -1) {
        partPath = partPath.substr(0, sectionSeparator)
        partName = partPath.substr(1, sectionSeparator)
      }
      topic = arr[partName][2]
    } else {
      if (sectionSeparator !== -1) {
        topic = currentPath.substr(1, sectionSeparator)
      } else {
        topic = currentPath.substr(1, currentPath.length - 1)
        partName = topic
      }
    }

    return (
      <Fragment>
        <Helmet title={frontmatter.title} />
        <PagesContext.Provider
          value={{
            all: allPages,
            current: { frontmatter: frontmatter, filePath: filePath },
          }}
        >
          <LoginStateContextProvider>
            <Navbar />
            <ScrollIndicator />
            <Layout>
              <Header
                color={arr[partName][0]}
                blob={arr[partName][1]}
                textcolor={arr[partName][3]}
              />
              <Fragment>
                <Container>
                  <ContentWrapper>
                    <BoldHeading color={arr[partName][0]}>
                      <span
                        style={{
                          textAlign: "center",
                          fontSize: "2.4rem",
                          lineHeight: "1.2",
                          fontFamily: "Josefin Sans",
                          color: "#333",
                          opacity: 0.5,
                          fontWeight: 600,
                        }}
                      >
                        {`${partNumber}${dot}${subSectionNumber}`}
                      </span>
                      <H3>{frontmatter.title}</H3>
                      <h5>{topic}</h5>
                    </BoldHeading>
                    {renderAst(htmlAst)}
                    <EndOfSubSection />
                  </ContentWrapper>
                </Container>
                {/* <CoursePageFooter /> */}
              </Fragment>
            </Layout>
          </LoginStateContextProvider>
        </PagesContext.Provider>
      </Fragment>
    )
  }
}

export const pageQuery = graphql`
  query ($path: String!) {
    page: markdownRemark(frontmatter: { path: { eq: $path } }) {
      htmlAst
      html
      frontmatter {
        path
        title
      }
      fileAbsolutePath
    }
    allPages: allMarkdownRemark {
      edges {
        node {
          id
          frontmatter {
            path
            title
          }
        }
      }
    }
  }
`
